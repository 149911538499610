import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    body: "Roboto",
    heading: "Roboto"
  },
  color: {
    primary: "#D9B954"
  }
})

export default theme