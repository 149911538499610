// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ako-pracujem-tsx": () => import("./../../../src/pages/ako-pracujem.tsx" /* webpackChunkName: "component---src-pages-ako-pracujem-tsx" */),
  "component---src-pages-chcem-sa-stat-maklerom-tsx": () => import("./../../../src/pages/chcem-sa-stat-maklerom.tsx" /* webpackChunkName: "component---src-pages-chcem-sa-stat-maklerom-tsx" */),
  "component---src-pages-chcem-sa-stat-tiperom-tsx": () => import("./../../../src/pages/chcem-sa-stat-tiperom.tsx" /* webpackChunkName: "component---src-pages-chcem-sa-stat-tiperom-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instagramove-odkazy-tsx": () => import("./../../../src/pages/instagramove-odkazy.tsx" /* webpackChunkName: "component---src-pages-instagramove-odkazy-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-realitne-lokality-index-tsx": () => import("./../../../src/pages/realitne-lokality/index.tsx" /* webpackChunkName: "component---src-pages-realitne-lokality-index-tsx" */),
  "component---src-pages-realitne-lokality-strapi-location-slug-tsx": () => import("./../../../src/pages/realitne-lokality/{StrapiLocation.slug}.tsx" /* webpackChunkName: "component---src-pages-realitne-lokality-strapi-location-slug-tsx" */),
  "component---src-pages-realitne-sluzby-tsx": () => import("./../../../src/pages/realitne-sluzby.tsx" /* webpackChunkName: "component---src-pages-realitne-sluzby-tsx" */),
  "component---src-pages-realitny-magazin-index-tsx": () => import("./../../../src/pages/realitny-magazin/index.tsx" /* webpackChunkName: "component---src-pages-realitny-magazin-index-tsx" */),
  "component---src-pages-realitny-magazin-strapi-article-slug-tsx": () => import("./../../../src/pages/realitny-magazin/{strapiArticle.slug}.tsx" /* webpackChunkName: "component---src-pages-realitny-magazin-strapi-article-slug-tsx" */)
}

